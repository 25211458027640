.gallery-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-grow: 1;
  /* padding-top: 20px; */
  min-width: 333px;
}

.menu-search-container {
  display: none;
}

.rotating-marble {
  width: 30%;
  display: flex;
  justify-content: center;
  animation: rotate1 10s linear infinite;
  box-shadow: 0px 0px 15px #5b36ff83;
  border-radius: 50%;
  position: relative;
  top: 100px;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.up-top-msg {
  /* background-color: rgb(255, 255, 255); */
  background-image: linear-gradient(
    165deg,
    rgba(0, 0, 0, 0.721),
    rgba(255, 255, 255, 0.284),
    rgba(0, 0, 0, 0.721)
  );
  text-align: left;
  font-weight: bold;
  padding: 15px 15px 10px 15px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva,
    Verdana, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.9rem;
  gap: 20px;
  color: rgb(238, 238, 238);
}

.message-link-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
}

.site-msg-link-ig {
  /* background-color: rgba(0, 0, 255, 0.561); */
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.561), rgba(0, 0, 255, 0.721));
  border-radius: 10px;
}

.contact-wrapper {
  /* min-width: 8`0px; */
  display: grid;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.491);
  padding: 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 255, 0.606);
}

.site-msg-span {
  background-color: rgba(0, 0, 0, 0.444);
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 255, 0.606);
  min-height: 141px;
}
@media (min-width: 1024px) {
  .site-msg-span {
    min-height: 132px;
  }
  .up-top-msg {
    font-size: 1.5rem;
  }

  .site-message-contact-header {
    justify-self: center;
    font-size: 1rem;
  }

  .contact-wrapper {
    min-width: 185px;
    min-height: 131px;
    display: grid;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .message-link-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .menu-search-container {
    /* background-image: linear-gradient(145deg, #7777f3bd, #5b36ff83); */
    background-image: linear-gradient(145deg, rgb(74, 74, 74), black);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    /* border-top: 2px solid #5b36ff83; */
    border-top: 2px solid black;
    /* border-bottom: 2px solid #5b36ff83; */
    border-bottom: 2px solid black;
    padding: 30px 0;
  }
  .header-section {
    display: none;
  }
}
