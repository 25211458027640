.discount-input {
  width: 30%;
  height: 50px;
  font-size: 2rem;
  text-align: right;
}

.discount-form {
  width: 100%;
  /* background-image: linear-gradient(315deg, #919191c4, #5b36ff83); */
  /* background-color: grey; */
  min-width: 350px;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid black;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 85px 60px 100px 250px 100px;
  justify-items: center;
  overflow: auto;
  color: white;
}
.discount-form h2 {
  text-align: center;
}

.discount-submit-btn {
  height: 50px;
  font-size: 1rem;
  border-radius: 8px;
  padding: 0 15px 0 15px;
  background: none;
  background-image: linear-gradient(125deg, rgb(255, 255, 255), rgba(0, 0, 0, 0.23));
}

.discount-form section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.discount-form div {
  display: grid;
}

.discount-form h2 {
  min-width: 300px;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
}

.discount-form {
  width: 100%;
  max-width: 600px; /* Increased max-width for better spacing */
  padding: 20px;
  /* background-color: #ffffff; */
  background-image: linear-gradient(125deg, rgb(255, 255, 255), rgba(0, 0, 0, 0.23));

  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.form-section {
  margin-bottom: 30px; /* Increased margin for better spacing */
  justify-self: flex-start;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.percentage-input {
  max-width: 120px; /* Slightly increased width for better usability */
}

.radio-group {
  position: relative;
  display: grid;
  gap: 6px;
  justify-self: flex-start;
}
.form-section2 {
  /* margin-top: 40px; */
}
.radio-label {
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.radio-label input {
  margin-right: 5px;
  cursor: pointer;
}

.message-input {
  resize: none;
  position: relative;
  /* top: -40px; */
  width: 320px; /* Ensure the textarea is as wide as the input fields */
}

.submit-button {
  width: 100%;
  padding: 14px; /* Slightly increased padding for a more substantial button */
  /* background-color: #007bff;
   */
  background-image: linear-gradient(125deg, rgb(80, 80, 80), rgb(0, 0, 0));

  color: white;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* margin-top: 100px; */
}

.submit-button:hover {
  background-color: #0056b3;
}
@media (min-width: 768px) {
  .message-input {
    resize: none;
    width: 500px; /* Ensure the textarea is as wide as the input fields */
  }
}
@media (min-width: 1024px) {
}
