.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  /* background-color: rgb(176, 176, 253);
  background: linear-gradient(270deg, #7777f3bd, #5b36ff83, #2f00ff83); */
  /* background-size: 400% 400%; */
  /* animation: ocean 10s ease-in-out infinite; */
  letter-spacing: 1.5px;
  position: relative;
  height: calc(100vh - 90px);
}

@keyframes ocean {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auth-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 150px;
  padding: 10px 0;
  background: linear-gradient(270deg, #7777f3bd, #5b36ff83);
  border-top: 1px solid #2f00ff83;
  border-bottom: 1px solid #2f00ff83;
  backdrop-filter: blur(5px);
  transition: transform 0.6s ease;
  will-change: transform;
  z-index: 2000;
}

.auth-section-container.retracted {
  transform: translateX(-100%);
}

.retract-button {
  position: absolute;
  bottom: 10px;
  z-index: 10;
  left: 30px;
  transform: translateX(-50%);
  transition: 500ms;
  background-color: #5b36ff83;
  color: white;
  padding: 3px 10px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
  height: 40px;
  width: 40px;
}

.retract-button:hover {
  background-color: #4427b5;
}

.hidden-text-link {
  opacity: 0;
}

.sign-in-sign-out {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.auth-link {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.email-container {
  display: flex;
  flex-direction: column;
}

.input-auth {
  padding: 0.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 1rem;
}

.input-auth:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.button-auth {
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: rgba(86, 86, 253, 0.54);
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
  width: 150px;
}

.active {
  /* background-image: linear-gradient(315deg, #7777f3c4, #5b36ff83); */
  background-image: linear-gradient(315deg, black, grey);
}

.marble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.rotating-marble {
  width: 50%;
  display: flex;
  justify-content: center;
  animation: rotate 10s linear infinite;
  box-shadow: 0px 0px 15px #5b36ff83;
  border-radius: 50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scene {
  width: 152px;
  height: 152px;
  position: relative;
  top: 60px;
  z-index: 1000;
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: rotate66 40s infinite linear;
  z-index: 1000;
}
@keyframes rotate66 {
  0% {
    transform: rotateX(180deg) rotateY(15deg) rotateZ(15deg) translateX(0px) translateY(-100px);
  }
  25% {
    transform: rotateX(103deg) rotateY(96deg) rotateZ(123deg) translateX(0px) translateY(0px);
  }
  50% {
    transform: rotateX(180deg) rotateY(187deg) rotateZ(172deg) translateX(100px) translateY(100px);
  }
  75% {
    transform: rotateX(280deg) rotateY(265deg) rotateZ(292deg) translateX(0px) translateY(0px);
  }
  100% {
    transform: rotateX(540deg) rotateY(375deg) rotateZ(375deg) translateX(0px) translateY(-100px);
  }
}
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 10px solid #5b36ff83;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);
  z-index: 1000;
}

.front {
  animation: openSesame 40s infinite, changeOpacity 40s infinite;
  background-image: url('/public/bubbler.jpeg');
  z-index: 1000;
}

@keyframes openSesame {
  0% {
    transform: rotateY(0deg) translateZ(75px);
  }
  38% {
    transform: rotateY(0deg) translateZ(75px);
  }
  43% {
    transform: rotateY(90deg) translateZ(75px);
  }
  57% {
    transform: rotateY(90deg) translateZ(75px);
  }
  60% {
    transform: rotateY(0deg) translateZ(75px);
  }
  100% {
    transform: rotateY(0deg) translateZ(75px);
  }
}

@keyframes changeOpacity {
  0% {
    opacity: 1;
  }
  48% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  58% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.back {
  transform: rotateY(180deg) translateZ(75px);
  animation: changeImage 40s infinite;
  z-index: 1000;
}

.back::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(ellipse at center, #5b36ff83 0%, #ffffff8f 100%);
  opacity: 0;
  animation: flashEffect 40s infinite;
}
@keyframes flashEffect {
  0% {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes changeImage {
  0% {
    background-image: url('/public/minitube.JPG');
  }
  50.2% {
    background-image: url('/public/minitube.JPG');
  }
  50.3% {
    background-image: url('/public/logo-sq.png');
  }
  50.7% {
    background-image: url('/public/minitube.JPG');
  }
  100% {
    background-image: url('/public/minitube.JPG');
  }
}

.right {
  transform: rotateY(90deg) translateZ(75px);
  background-image: url('/public/knaildriver.jpeg');
  z-index: 1000;
}

.left {
  transform: rotateY(-90deg) translateZ(75px);
  background-image: url('/public/7.jpg');
  z-index: 1000;
}

.top {
  transform: rotateX(90deg) translateZ(75px);
  background-image: url('/public/6.jpg');
  z-index: 1000;
}

.bottom {
  transform: rotateX(-90deg) translateZ(75px);
  background-image: url('/public/sherlock.jpeg');
  z-index: 1000;
}

@keyframes rotate {
  0% {
    transform: rotateX(15deg) rotateY(15deg) rotateZ(15deg) translateX(-100px) translateY(-20px)
      scaleX(1.2) scaleY(1.2) scaleZ(1.2);
  }
  25% {
    transform: rotateX(103deg) rotateY(96deg) rotateZ(90deg) translateX(0px) translateY(25px);
  }
  50% {
    transform: rotateX(162deg) rotateY(187deg) rotateZ(180deg) translateX(100px) translateY(200px)
      scaleX(4.9) scaleY(4.9) scaleZ(4.9);
  }
  75% {
    transform: rotateX(298deg) rotateY(265deg) rotateZ(310deg) translateX(0px) translateY(25px);
  }
  100% {
    transform: rotateX(375deg) rotateY(375deg) rotateZ(375deg) translateX(-100px) translateY(-20px)
      scaleX(1.2) scaleY(1.2) scaleZ(1.2);
  }
}
/* 2nd cube  */
.scene2 {
  width: 50px;
  height: 50px;
  position: relative;
  top: 80px;
  left: 70px;
  z-index: 1500;
}
.cube2 {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: rotate2 40s infinite linear;
  z-index: 1500;
}

.face2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #5b36ff;
  background-color: #5b36ff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgb(255, 255, 255);
  z-index: 1500;
}
.front2 {
  transform: rotateY(0deg) rotateZ(90deg) translateZ(25px);
  background-image: url('/public/logo-sq.png');
  z-index: 1500;
}

.back2 {
  transform: rotateY(180deg) rotateZ(270deg) translateZ(25px);
  background-image: url('/public/logo-sq.png');
  z-index: 1500;
}

.right2 {
  transform: rotateY(90deg) translateZ(25px);
  background-image: url('/public/marble-css.png');
  z-index: 1500;
}

.left2 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(25px);
  background-image: url('/public/marble-css.png');
  z-index: 1500;
}

.top2 {
  transform: rotateX(90deg) rotateZ(270deg) translateZ(25px);
  background-image: url('/public/logo-sq.png');
  z-index: 1500;
}

.bottom2 {
  transform: rotateX(-90deg) rotateZ(270deg) translateZ(25px);
  background-image: url('/public/logo-sq.png');
  z-index: 1500;
}

@keyframes rotate2 {
  0% {
    transform: rotateX(90deg) rotateY(-100deg) rotateZ(-120deg) translateX(0px) translateY(0px)
      scaleX(0.6) scaleY(0.6) scaleZ(0.6);
    box-shadow: none;
  }
  25% {
    transform: rotateX(60deg) rotateY(150deg) rotateZ(75deg) translateX(-25px) translateY(0px)
      scaleX(1) scaleY(1) scaleZ(1);
  }

  35% {
    transform: rotateX(103deg) rotateY(96deg) rotateZ(90deg) translateX(-80px) translateY(0px)
      scaleX(0.5) scaleY(0.5) scaleZ(0.5);
  }

  41% {
    box-shadow: none;
  }
  42% {
    box-shadow: 0px 0px 100px 100px white;
  }

  50% {
    transform: rotateX(210deg) rotateY(120deg) rotateZ(300deg) translateX(-120px) translateY(0px)
      scaleX(0) scaleY(0) scaleZ(0);
  }

  53% {
    box-shadow: none;
  }

  68% {
    transform: rotateX(275deg) rotateY(460deg) rotateZ(260deg) translateX(0px) translateY(0px)
      scaleX(1) scaleY(1) scaleZ(1);
  }

  80% {
    transform: rotateX(100deg) rotateY(380deg) rotateZ(260deg) translateX(0px) translateY(0px)
      translateZ(-200px) scaleX(1.5) scaleY(1.5) scaleZ(1.5);
  }

  85% {
    transform: rotateX(180deg) rotateY(260deg) rotateZ(280deg) translateX(0px) translateY(0px)
      translateZ(-75px) scaleX(2) scaleY(2) scaleZ(2);
  }
  89% {
    transform: rotateX(-20deg) rotateY(90deg) rotateZ(60deg) translateX(0px) translateY(0px)
      scaleX(2.5) scaleY(2.5) scaleZ(2.5);
  }

  100% {
    transform: rotateX(90deg) rotateY(-460deg) rotateZ(-480deg) translateX(0px) translateY(0px)
      scaleX(0.6) scaleY(0.6) scaleZ(0.6);
    box-shadow: none;
  }
}

.scene2 {
  display: none;
}
@media (min-width: 1024px) {
  .cube {
    animation: rotate 40s infinite linear;
  }
  .scene2 {
    display: unset;
  }
} ;
