html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  box-sizing: border-box;
}

.new-post-form {
  background-image: linear-gradient(125deg, rgb(255, 255, 255), rgba(0, 0, 0, 0.23));
  background-color: rgba(0, 0, 0, 0.507);
  min-width: 350px;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #5b36ff83;
  box-sizing: border-box;
  display: grid;
  gap: 10px;
  grid-template-rows: auto;
  justify-items: center;
  overflow: auto;
}

.form-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
}

.image-input {
  box-sizing: border-box;
  width: 300px;
  background-color: rgb(255, 254, 254);
  font-size: large;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 2px;
  font-weight: bold;
  padding: 5px;
}

.shadow-border {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid rgb(127, 127, 127);
  border-bottom: 2px solid rgb(127, 127, 127);
}

#form-title-header {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  justify-self: center;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
}

.file-upload-btn {
  background-color: rgb(176, 176, 253);
  box-sizing: border-box;
  padding: 10px;
  width: 300px;
  margin: auto;
  font-size: 1rem;
  border-radius: 5px;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
}
.form-comp:hover {
  background-color: rgb(127, 127, 127);
}

.description {
  box-sizing: border-box;
  max-width: 300px;
  max-height: 75px;
  min-width: 300px;
  min-height: 75px;
  background-color: rgb(255, 255, 255);
  font-size: large;
  position: relative;
  border-radius: 5px;
  padding: 5px;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.submit-btn {
  background-color: rgb(176, 176, 253, 0.5);
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 50%;
  padding: 5px 5px 3px 5px;
  box-shadow: 2px 2px 4px 0 black;
  cursor: pointer;
  margin-top: 50px;
}
.submit-btn:active {
  background-color: rgba(163, 163, 231, 0.5);
  box-shadow: inset 2px 2px 5px #d3c9ff;
  position: relative;
  left: 1px;
  top: 1px;
}

.new-post-icon {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}
option {
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
  font-weight: bold;
}

.loading {
  animation: scaleAnimation 6s infinite; /* 1s duration and loop infinitely */
  width: 30%;
  min-width: 200px;
  margin: auto;
  border-radius: 20px;
  color: #5b36ff;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
  font-weight: bold;
  text-shadow: 0px 0px 10px rgba(0, 255, 26, 0.495);
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(0.5);
    opacity: 0.2;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
.loading-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  padding: 0 0rem;
  letter-spacing: 1.5px;
  position: relative;
  width: 100%;
}

.loading-div-wrapper {
  height: calc(100vh - 90px);
}

.thumbnail {
  width: 60px;
  margin: 5px;
  border-radius: 5px;
}
.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 10px;
  border-radius: 0.5rem;
  background-color: rgba(245, 245, 220, 0.557);
  border: 1px solid #5b36ff59;
}

.input-with-dollar-sign {
  padding-left: 25px;
}
.dollar-sign-span {
  position: relative;
  left: -295px;
  top: 3px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5b36ff;
  z-index: 300;
}

.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Add space between thumbnail wrappers */
  margin-top: 30px;
}

.thumbnail-wrapper {
  position: relative;
  display: inline-block;
}

.thumbnail {
  width: 100px; /* Change this to the desired thumbnail size */
  height: 100px; /* Change this to the desired thumbnail size  */
  object-fit: cover;
}

.delete-button-form {
  position: absolute;
  top: -5px;
  right: -5px;
  background: rgb(253, 34, 34);
  color: white;
  font-weight: bold;
  width: 28px;
  height: 28px;
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
  border: 3px solid rgb(165, 167, 197);
  /* border: 3px solid rgb(245, 245, 220); */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: block;
  /* padding-bottom: 1px; */
  padding: 0px;
  box-shadow: inset 0px 0px 0px 2px white;
  box-sizing: border-box;
}
.form-wrapper {
  letter-spacing: 1.5px;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

@keyframes ocean {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.desk-price-input-wrapper {
  position: relative;
  left: 10px;
}
.form-admin-panel {
  display: none;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
.file-upload-label {
  border: 2px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #ccc;
}
.file-upload-label:hover {
  cursor: pointer;
}

.price-in-form {
  display: grid;
  justify-content: center;
}

.labels-form-inputs {
  display: block;
}

.thumbnails-container {
  margin-top: 20px;
  height: 250px;
  overflow-y: scroll;
  display: flex;
  align-items: start;
}

.dropzone {
  margin-top: 20px;
}

.form-wrapper .price-input {
  text-align: right;
  justify-self: flex-end;
}

.sold-radio-group {
  border: solid 1px black;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  min-width: 300px;
}

/*

@media rules start ======================================================
mobile BELOW this point due to min-width parameter
*/

.btn-container {
  margin-top: -30px;
}
@media (min-width: 768px) {
  .selling-link {
    grid-row: 4;
    grid-column: 2;
  }

  .image-input-button {
    grid-row: 7;
    grid-column: 2;
  }

  .new-post-form {
    width: 700px;
    grid-template-rows: 75px 75px 75px 75px 75px 75px 75px 75px 75px;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    justify-items: center;
  }

  #form-title-header {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  .desk-cat-input {
    grid-row: 2;
    grid-column: 1;
  }

  .desk-title-input {
    grid-row: 3;
    grid-column: 1;
  }

  .desk-desc-input {
    grid-row: 6;
    grid-column: 1;
    margin-top: 70px;
  }

  .desk-price-input {
    grid-row: 2;
    grid-column: 2;
  }

  .desk-image-input {
    grid-row: 3;
    grid-column: 2;
  }
  .thumbnails-container {
    grid-row: 7;
    grid-column: 2;
    min-height: 250px;
    overflow-y: scroll;
    display: flex;
    align-items: start;
  }
  .dropzone {
    grid-row: 5;
    grid-column: 2;
  }
  .btn-container {
    grid-row: 9;
    grid-column: 1 / 3;
    margin-top: 50px;
  }

  .description {
    max-height: 600px;
    height: 300px;
  }

  .sold-radio-group {
    border: solid 1px black;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    min-width: 300px;
    grid-row: 4;
    grid-column: 1;
  }
}

@media (min-width: 1024px) {
  .form-wrapper {
    grid-template-columns: 200px 1fr;
  }
  .form-admin-panel {
    background-color: #00000083;
    border: 2px solid #2f00ff83;
    padding: 10px;
    display: block;
    height: 100%;
    box-sizing: border-box;
  }
  .form-admin-panel-section {
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 5px auto;
    padding: 0 0rem;
  }
}
