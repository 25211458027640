header {
  background-color: #000000;
  height: 90px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-width: 333px;
}
.logo {
  height: 80px;
  margin-top: 5px;
}

.title {
  font-size: 1.5em;
  grid-area: title;
  margin: 0;
  position: relative;
  top: -25px;
  left: -15px;
  font-family: 'Gruppo', cursive;
  min-width: 207px;
}

.header-section p {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
}

/* style for Link component */
.new-link {
  color: white;
  text-decoration: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px 4px 5px 4px;
  background-image: linear-gradient(315deg, black, grey);
  font-size: 1rem;
  display: flex;
  transition: 2000ms;
  width: 120px;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.new-link:active {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  transition: 400ms ease-in-out;
  transform: scale(1.1);
}

.upload-icon {
  height: 60px;
  width: 60px;
}

/* style for button */
.signout-button {
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 4px 4px 2px 4px;
  /* background-color: rgb(123, 123, 255); */
  background-image: linear-gradient(315deg, grey, black, grey);
  font-size: 0.9rem;
  display: flex;
  /* font-family: 'Shadows Into Light', cursive; */
  transition: 100ms;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 40px;
}

/* style for button when hovered or focused */

.signout-button:focus {
  color: rgb(255, 255, 255);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 250ms ease-in-out;
  transform: scale(1.1);
  background-color: rgb(0, 0, 0, 0.5);
  transition: 400ms;
}

.signout-nav-icon {
  height: 40px;
}
.user-email {
  font-size: 0.8rem;
  color: rgb(0, 255, 64);
  text-decoration: none;
  font-weight: bold;
}

.menu-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0px;
  background-color: #000000;
  /* background-image: linear-gradient(315deg, #838383 0%, #2f00ffa1 74%); */
  z-index: 10;
  height: 0px;
  overflow: hidden;
  transition: 250ms ease-in-out;
  position: absolute;
  top: 90px;
  z-index: 10;
  min-width: 340px;
}

.menu-div.open {
  height: 100px;
  position: absolute;
  transform: translateY(-100%);
  border-bottom: 2px solid #2f00ff;
}

.menu-icon,
.menu-no-user {
  padding: 0px;
  border-radius: 50%;
  margin: 0 20px 0 0px;
  height: 40px;
  box-shadow: 0 0 8px 0 white;
  transition: 200ms ease-in;
  box-shadow: 0 0 0 0.2rem rgba(50, 50, 50, 0.5);
}
.menu-no-user {
  display: block;
  width: 40px;
  margin-right: 20px;
}
.menu-icon:hover,
.menu-no-user:hover {
  cursor: pointer;
  transition: 200ms ease-in;
  box-shadow: 0 0 25px 0 rgba(255, 255, 255, 0.5);
}
.menu-icon:active,
.menu-no-user:active {
  background-color: #2f00ff;
  cursor: pointer;
  transition: 200ms ease-in;
  box-shadow: 0 0 25px 0 rgba(255, 255, 255, 0.5);
}
.new-post-span {
  margin-top: -4px;
}

.menu-btn {
  display: none;
}

.username {
  position: absolute;
  left: 75px;
  top: 18px;
  color: white;
  font-size: 0.75rem;
}

/*



@media rules begin here  //////////////////////////////////////////////////////////////////////////////////




*/

@media (min-width: 420px) {
  .menu-btn {
    display: block;
  }
}

@media (min-width: 768px) {
  .new-link {
    font-size: 1.5rem;
  }
  .signout-button {
    font-size: 1.4rem;
  }
}

@media (min-width: 1024px) {
  .signout-button {
    /* color: white; */
    /* background-color: transparent; */
    /* border: none; */
    /* cursor: pointer; */
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5); */
    /* border-radius: 5px; */
    /* padding: 4px 4px 2px 4px; */
    /* background-color: rgb(123, 123, 255); */
    /* background-image: linear-gradient(315deg, grey, black, grey); */
    font-size: 1rem;
    /* padding: 0px; */
    /* display: flex; */
    /* flex-direction: row; */
    /* font-family: 'Shadows Into Light', cursive; */
    /* transition: 100ms; */
    /* justify-content: center; */
    /* width: 118px; */
  }
  .menu-div {
    display: none;
  }

  .menu-div-adapt {
    display: none;
  }

  .new-link:active,
  .signout-button:active {
    transform: scale(1);
    transition: 500ms;
  }
  .signout-button,
  .new-link {
    box-shadow: 0 0 10px 0 #7777f3bd;
  }
}
