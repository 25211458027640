html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.mobile-title-desk {
  display: none;
}
.mobile-title {
  display: block;
}
.admin-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  height: calc(100vh - 90px);
}

.admin-panel {
  border: 2px solid #00000083;
  display: none;
  height: 100%;
  box-sizing: border-box;
}

.list-container {
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(auto-fill, minmax(50px, auto));
  justify-items: flex-start;
  align-items: flex-start;
  margin: 0px auto;
  padding: 0 0rem;
  height: 90vh;
  background: linear-gradient(
    270deg,
    rgba(89, 89, 89, 0.458),
    rgba(40, 40, 40, 0.532),
    rgba(21, 21, 21, 0.577)
  );

  background-color: rgba(0, 0, 0, 0.51);
  position: relative;
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

@keyframes ocean {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.post {
  width: 100%;
  height: 55px;
  background-color: white;
  display: grid;
  grid-template-columns: 60px 150px 100px 1fr 1fr;
  align-items: center;
  justify-items: self-start;
  padding: 0 10px 0px 0px;
  box-sizing: border-box;
  border: 1px solid black;
  white-space: nowrap;
}

.admin-prod-img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  object-position: center;
}

.delete-button,
.edit-button {
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  margin: 0 5px;
}

.admin-prod-btn-cont {
  justify-self: flex-end;
  display: block;
  margin: 0px 0;
}

.grid-s1 {
  grid-column-start: 1;
  font-weight: bold;
}
.grid-s2 {
  grid-column-start: 2;
  font-weight: bold;
}
.grid-s3 {
  grid-column-start: 3;
  font-weight: bold;
}
.grid-s4 {
  grid-column-start: 4;
  font-weight: bold;
}
.grid-s5 {
  grid-column-start: 5;
  font-weight: bold;
}
.grid-s6 {
  grid-column-start: 6;
  font-weight: bold;
}
.grid-s7 {
  grid-column-start: 7;
  font-weight: bold;
}

.grid-e1 {
  grid-column-end: 1;
  font-weight: bold;
}
.grid-e2 {
  grid-column-end: 2;
  font-weight: bold;
}
.grid-e3 {
  grid-column-end: 3;
  font-weight: bold;
}
.grid-e4 {
  grid-column-end: 4;
  font-weight: bold;
}
.grid-e5 {
  grid-column-end: 5;
  font-weight: bold;
}
.grid-e6 {
  grid-column-end: 6;
  font-weight: bold;
}
.grid-e7 {
  grid-column-end: 7;
  font-weight: bold;
}

.desc-desk,
.cat-desk {
  display: none;
  white-space: nowrap;
}

.grayed-out {
  opacity: 0.5;
  pointer-events: none;
}
.sold-hightlight {
  color: rgb(0, 153, 23);
  position: relative;
  top: -10px;
}
/*
  
  
  @media BEGIN =================================================
  
  
  */
@media (min-width: 414px) {
}

@media (min-width: 768px) {
  .post {
    width: 100%;
    height: 100px;
    background-color: white;
    display: grid;
    grid-template-columns: 120px 300px 75px auto;
    align-items: flex-start;
    padding: 0 10px 0px 0px;
    box-sizing: border-box;
    border: 1px solid black;
    white-space: nowrap;
  }
  .admin-prod-btn-cont {
    justify-self: flex-end;
    display: block;
    margin: 16px 0;
  }
  .admin-prod-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  .list-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, 100px);
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 0px auto;
    padding: 0 0rem;
    gap: 5px;
  }

  .mobile-title-desk {
    display: block;
    max-width: 300px;
    height: 100px;
    overflow: hidden;
    white-space: normal;
  }
  .mobile-title {
    display: none;
  }
  .cat-desk {
    display: unset;
    justify-self: center;
  }
  .delete-button,
  .edit-button {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    margin: 5px 5px;
  }
}

@media (min-width: 1024px) {
  .post {
    grid-template-columns: 120px 300px 150px 150px auto;
  }
  .admin-panel {
    display: block;
  }
  .admin-container {
    grid-template-columns: 200px 1fr 250px;
    grid-template-rows: 1fr;
  }
  .admin-panel-section {
    display: grid;
    justify-items: center;
    align-items: flex-start;
    padding: 0 0rem;
    background-color: black;
    background-color: #00000083;
    border: 2px solid #2f00ff83;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
  }

  .menu-icon {
    display: none;
  }

  .signout-button-adapt {
    width: 120px;
  }

  .new-link,
  .signout-button {
    display: flex;
    justify-content: space-between;
    font-family: 'Shadows Into Light', cursive;
    margin-bottom: 5px;
  }

  .new-link:hover,
  .signout-button:hover {
    background-color: #ccc;
    transform: scale(1);
    background: linear-gradient(to bottom right, white, grey, black, grey, white);
    background-size: 150% 150%;
  }
  .new-link:active,
  .signout-button:active {
    transform: scale(1);
    transition: 250ms;
    color: black;
    background-size: 400% 400%;
  }
}

@media (min-width: 1200px) {
  .desc-desk {
    display: block;
    white-space: normal;
    overflow: hidden;
    width: 250px;
    height: 65px;
  }
  .post {
    grid-template-columns: 120px 300px 75px 150px 250px;
  }
}
