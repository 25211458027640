.btn-adjust {
}

.gallery-display-container {
  /* width: 100%; */
  margin: 0 0 5px 0;
  padding: 0 0;
  box-shadow: 0 0 10px 0 #808080bd;
  transition: 300ms;
  width: 190px;
  /* height: 100%; */
  transition: 300ms;
  display: flex;
  flex-direction: column;
}
.gallery-display {
  /* background-image: linear-gradient(145deg, #7777f3bd, #5b36ff83); */
  background-image: linear-gradient(145deg, grey, black);
  border: 1px solid black;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 0 0;
  display: grid;
  justify-content: center;
  max-width: 200px;
  transition: 300ms;
  min-height: 300px;
}

.gallery-display .p-gallery,
.a-gallery {
  text-decoration: none;
  color: rgb(0, 0, 0);
  display: block;
  position: relative;
  top: 0px;
  font-family: 'Shadows Into Light', cursive;
  padding: 0;
  transition: 300ms;
}

.gallery-img {
  max-width: 190px;
  transition: 300ms;
}

.gallery-title {
  display: inline;
  margin: 0 0 5px 0;
  font-size: 1rem;
  font-weight: bold;
  padding: 0px;
  height: 1rem;
  transition: 300ms;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.gallery-desc {
  font-size: 0.8rem;
  font-weight: 600;
  color: rgba(179, 179, 179, 0.512);
  padding: 0px 8px;
  border-radius: 5px;
  margin: 3px 0 0 0;
  display: inline-block;
  height: 1.4rem;
  line-height: 1rem;
  transition: 300ms;
  font-family: 'Roboto', sans-serif;
  color: #ffffdbe3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.price-category-wrapper {
  /* display: flex; */
  /* justify-self: flex-end; */
  align-self: flex-end;
}

.gallery-price {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(179, 179, 179, 0.512);
  padding: 0px 8px;
  border-radius: 5px;
  margin: 3px 0 0 0;
  height: 1.7rem;
  line-height: 1rem;
  transition: 300ms;
  font-family: 'Roboto', sans-serif;
  /* color: #ffffdbe3; */
  color: white;
  display: inline-block;
  border: 1px solid #ffffdbe3;
  /* background-color: #2f00ff83; */
  background-color: black;
  /* display: grid; */
  padding-top: 5px;
}

.gallery-category {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(179, 179, 179, 0.512);
  padding: 0px 8px;
  border-radius: 5px;
  margin: 3px 0 0 0;
  height: 1.7rem;
  line-height: 1rem;
  transition: 300ms;
  font-family: 'Roboto', sans-serif;
  color: #ffffdbe3;
  display: inline;
}

.gallery-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: 300ms;
  padding: 0 10px 0 10px;
}

.gallery-list-container {
  /* background-color: rgb(176, 176, 253); */
  /* background: linear-gradient(270deg, #7777f3bd, #5b36ff83, #2f00ff83); */
  /* background-size: 400% 400%; */
  /* animation: ocean 10s ease-in-out infinite; */
}

/*

@ media starts here =============================================

*/

@media (min-width: 414px) {
  .gallery-list-container {
    grid-template-columns: repeat(2, 1fr);
    transition: 300ms;
  }

  .gallery-img,
  .gallery-display {
    width: 100%;
    border-radius: 5px 5px 0 0;
    transition: 300ms;
  }
}

@media (min-width: 768px) {
  .gallery-list-container {
    grid-template-columns: repeat(5, 1fr);
    transition: 300ms;
  }

  .gallery-display-container {
    width: 300px;
    height: 400px;
    transition: 300ms;
    margin: 0 0 5px 0;
  }

  .gallery-display:hover {
    transform: scale(1.01);
    box-shadow: 0 0 10px #ccc;
    background-color: rgb(83, 25, 255);
    transition: 300ms;
  }

  .gallery-display {
    width: 100%;
    border-radius: 5px 5px 0 0;
    max-width: 400px;
    height: 400px;
    transition: 300ms;
  }
  .gallery-img {
    max-width: 300px;
    max-height: 300px;
    transition: 300ms;
  }

  .gallery-title {
    font-size: 1.3rem;
    height: 1.7rem;
    line-height: 1.8rem;
    transition: 300ms;
  }
  .gallery-category,
  .gallery-desc,
  .gallery-price {
    font-size: 1rem;
    transition: 300ms;
  }
}
