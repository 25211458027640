.btn-adjust {
  position: relative;
  bottom: unset;
  background-color: unset;
}

.loading-div {
  animation: scaleAnimation 6s infinite;
  width: 30%;
  min-width: 200px;
  margin: auto;
  border-radius: 20px;
  color: #5b36ff;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 2px;
  font-weight: bold;
  text-shadow: 0px 0px 10px rgba(0, 255, 26, 0.495);
}
.detail-top-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.post-detail-div {
  background-image: linear-gradient(145deg, rgb(128, 128, 128), rgba(68, 68, 68, 0.269));
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
}
.post-detail-div {
  font-size: 1.2rem;
  color: white;
  letter-spacing: 2px;
  padding: 0px 0px 0px 0px;
  min-width: 200px;
}
.gallery-image {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
}
.desc-price-container {
  display: grid;
  grid-template-rows: 4fr 1fr;
  padding: 10px 10px 10px 10px;
  background-color: black;
  border: 1px solid grey;
  /* height: 150px; */
  overflow: auto;
}

.title-container {
  display: grid;
  grid-template-columns: 50px auto;
  gap: 10px;
  padding: 8px 0px 4px 0px;
  background-color: black;
  border: 1px solid grey;
  border-bottom: none;
}

.title-cat-container {
  background-color: black;
  border: 1px solid grey;
  border-bottom: none;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
}

.category-label {
  font-size: 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  font-weight: bold;
  display: inline-block;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  height: 2.7rem;
  line-height: 3rem;
  margin-bottom: 5px;
}

.category-span {
  font-size: 1.2rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: inline;
  padding: 5px 20px 5px 20px;
  line-height: 3rem;
  margin: 5px 0 5px 5px;
  cursor: pointer;
  box-shadow: 0 0 7px 2px rgb(255, 255, 255);
}

.category-span:hover {
  background: none;
  background-color: rgba(0, 0, 255, 0.3);
}

.detail-title {
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-align: left;
  margin: 0;
  line-height: 1;
  padding: 8px 0px 15px 0px;
  color: #ffffdb;
  text-shadow: 0 0 2px #00000096;
  font-family: 'Raleway', sans-serif;
}

.price-details {
  font-size: 1.6rem;
  letter-spacing: 2px;
  font-weight: bolder;
  padding: 0px 5px;
  background-color: rgba(0, 0, 0, 0.786);
}

.desc-details {
  font-size: 1rem;
  color: #ffffdb;
  letter-spacing: 2px;
  font-weight: bolder;
  line-height: 1.2;
  text-shadow: 0 0 2px #00000096;
  font-family: 'Raleway', sans-serif;
  padding: 0px 10px;
}
.filler-div {
  background-color: black;
  width: 100%;
  margin-top: 6px;
}

.contact {
  margin-left: 0px;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
}

.contact a {
  text-decoration: none;
  margin-left: 40px;
  padding: 5px 10px;
  color: white;
  border: 2px solid grey;
  border-radius: 5px;
}
.contact a:hover {
  border-radius: 5px;
  border: 2px solid #ffffdb;
  box-shadow: 0 0 5px 0 #ffffdb;
  transition: 250ms;
  background: linear-gradient(
    to top left,
    #b6b6fbbd,
    #b6b6fbbd,
    #b6b6fbbd,
    #7777f3bd,
    #5b36ff83,
    #2f00ff83
  );
  background-size: 400% 400%;
  animation: shimmer 250ms ease-in;
  color: white;
}

@keyframes shimmer {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}
/* gallery image display */
.gallery-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.gallery-image,
.gallery-video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 1px solid grey;
}
.gallery-video:hover {
  cursor: pointer;
}
.swipe-button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.swipe-button:focus {
  outline: none;
}

.thumbnail-gallery {
  width: 50px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 #ffffff;
}

.thumbnail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 3px;
  background-color: black;
  border: 1px solid grey;
  padding: 5px;
}

/* modal */
.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  border-radius: 4px;
  outline: none;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.modal-close {
  position: absolute;
  top: 0px;
  right: 0px;
  background: rgb(253, 34, 34);
  color: white;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: 1px solid white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: block;
  padding-bottom: 1px;
}

.post-detail-div-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, minmax(50px, auto));
  flex-wrap: wrap;
  justify-items: flex-start;
  align-items: flex-start;
  margin: 0px auto;
  padding: 0 0rem;
  letter-spacing: 1.5px;
  position: relative;
  width: 100%;
  min-width: 350px;
  flex-grow: 1;
}

.back-btn {
  margin: 10px 0 15px 0;
}

.retract-button2 {
  z-index: 10;
  left: 1.8rem;
  transform: translateX(-50%);
  transition: 500ms;
  background-color: #00000083;
  color: white;
  padding: 3px 10px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Shadows Into Light', cursive;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
  height: 40px;
  width: 40px;
}

.modal-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  width: 40px;
  height: 40px;
}

.modal-navigation:hover {
  opacity: 1;
}

.modal-navigation.previous {
  left: 10px;
}

.modal-navigation.next {
  right: 10px;
}

.next {
  background-image: url('../../../public/next.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.previous {
  background-image: url('../../../public/back.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.detail-contact-wrapper {
  width: 100%;
  background-color: black;
  padding: 8px 10px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3px;
}

.detail-contact-text {
  position: relative;
  top: -20px;
}

.detail-contact-img-link-wrapper {
  display: flex;
  justify-content: space-around;
  width: 150px;
}

.detail-contact-img-link-wrapper a {
  text-decoration: none;
}

.gallery-on-sale {
  font-size: 0.7rem;
  color: yellow;
}
.detail-on-sale {
  font-size: 1rem;
  color: yellow;
}
.selling-link-wrapper {
  width: 100%;
  background-color: rgba(175, 236, 255, 0.8);
  padding: 8px 10px 0 25px;
  margin-top: 3px;
  box-shadow: inset 0px 0px 15px rgba(0, 4, 255, 0.6);
  color: black;
  grid-column: 2;
  grid-row: 5;
}
.selling-link-wrapper > a {
  text-decoration: none;
  color: inherit;
}

.selling-link-wrapper > a:visited {
  text-decoration: none;
  color: inherit;
}

.selling-link-span {
  position: relative;
  top: 7px;
  display: inline-block;
  font-weight: 500;
}

.shimmer {
  background: linear-gradient(
    120deg,
    black 0%,
    white 10%,
    rgba(255, 255, 255, 0.848) 11%,
    rgba(255, 255, 255, 0.591) 12%,
    rgba(255, 255, 255, 0.159) 13%,
    black 14%,
    black 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% auto;
  animation: shimmer 8s linear infinite;
  color: transparent;
}

@keyframes shimmer {
  100% {
    background-position: 0 0;
  }
  0% {
    background-position: 400% 0;
  }
}

.selling-logo-link {
  width: 52px;
  height: auto;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 15px rgb(255, 255, 255);
  top: -4px;
}

.inner-selling-link {
  display: flex;
  justify-content: space-around;
}

.inner-selling-link a span {
  text-decoration: none;
}

/*


@media rules begin here ////////////////////////////////////////////


*/
@media (max-width: 768px) {
  .detail-contact-wrapper {
    height: 100px;
  }
  .detail-contact-wrapper {
    width: 100%;
    background-color: black;
    padding: 8px 10px 35px 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 3px;
  }
}

@media (min-width: 768px) {
  /*
  */

  .gallery-on-sale {
    font-size: 1rem;
  }

  .post-detail-div {
    display: grid;
    grid-template-columns: 3fr 5fr;
    max-width: 750px;
  }

  .title-container {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .title-cat-container {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .gallery-container {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .btn-adjust {
    position: relative;
  }

  .thumbnail-container {
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0;
  }

  .desc-price-container {
    min-width: 225px;
    height: unset;
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .gallery-container,
  .gallery-image {
    margin: 0;
    border-radius: 0px;
  }
  .gallery-image:hover {
    cursor: pointer;
  }
  .thumbnail-gallery {
    width: 100px;
  }
}
@media (min-width: 1024px) {
  .back-btn {
    display: none;
  }
}
