#root,
.App {
  /* flex-grow: 1; */
  /* width: 100vw; */
  /* min-width: 424px; */
  /* height: 100%; */
}

html {
  background-image: url(/public/ocean.jpg);
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
